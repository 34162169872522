.slideShowContainer {
  @extend .flex-horizontal;
  @extend .flex-center;
  height: 540px;
}

.slideShowImg {
  flex: 0 1 60%;
  img {
    max-width: 100%;
    height: auto;
    max-height: 65vh;
  }
}