/* Style for the page layout */
.logout-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 999999999;
  margin: 16px;
}

body,
html,
.mdl-layout__container,
.mdl-layout {
  overflow: hidden;
}

.mdl-layout__content {
  display: flex;
}

.center-items {
  justify-content: center;
  align-items: center;
}

.content {
  flex: 0 1 1080px;
  margin: 1.5em auto;
}

.flex-child-1 {
  flex: 0 1 540px;
}

.page-heading {
  text-align: center;
}

.page-heading h4 {
  margin: 0;
}

.mdl-layout__header-row {
  height: 3.6em;
  justify-content: center;
  padding: 0;
  align-items: flex-end;
}

footer {
  position: absolute;
  z-index: 99999;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: $bg-primary;
}

footer div {
  margin-top: 0.15em;
}

footer p {
  margin: 0;
}

@media (max-width: 479px) {

  .content {
    flex: 1;
  }

}
