$button-color-border: #000 !default;
$button-border: 1px solid $button-color-border !default;


.button:hover {
	background-color: #000;
	color: $bg-primary;
	text-decoration: none;
}

.button {
	color: #000;
	cursor: pointer;
	max-width: 200px;
	padding: 8px 16px;
	background-color: transparent;
	border-radius: 3px;
	text-align: center;
	font-family: inherit;
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	border: $button-border;
	transition: color .5s linear;
	&.album-button {
		max-width: 300px;
		margin-top: 16px;
	}
}

.icon-button {
	align-items: center;
	display: flex;
	justify-content: center;
	min-height: 41px;
}

.layout-absolute-button {
	z-index: 2;
	position: fixed;
	right: 0;
	bottom: 0;
	margin: 16px;
	background-color: white;
}

.buttons-container {
	@extend .flex;
	justify-content: flex-end;
	.button {
		&:last-child {
			margin-left: 1rem;
		}
	}
}