/*
COLOR
*/

@import '../mdl/_variables';
@import '../mdl/_color-definitions';

$bg: unquote("rgb(#{$palette-grey-50})");
/*$bg: '#FFF';*/

body {
  color: #000;
}

a,
a:focus,
.mdl-layout__header {
  /*color: $text-color-primary;*/
  color: #000;
}

a:hover,
.active.effect {
  color: unquote("rgb(#{$palette-grey-500})")
}

.close:hover {
  opacity: 0.5;
}

.mdl-layout_header-sub a {
  color: white;
}

.content-holder {
  background: $bg-primary;
}

.mdl-layout_header-sub {
  background-color: $bg-secondary;
}

footer .mdi,
footer p {
  color: darken( $bg-primary, 20% );
}

footer .mdi:hover {
  color: unquote("rgb(#{$palette-grey-500})")
}