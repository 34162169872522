/* Navbar Styles */

.mdl-layout_header-sub {
  position: relative;
  top: 8em;
  z-index: 9000;
  height: 4em;
  display: none;
}

.mdl-layout_header-row-sub {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  box-sizing: border-box;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
}

nav a {
  position: relative;
  display: inline-block;
  margin: 0.5em 1.8em;
  outline: none;
  letter-spacing: 1px;
  font-weight: 400;
  text-shadow: 0 0 1px rgba(255,255,255,0.3);
}

nav a:hover,
nav a:focus {
  outline: none;
}

nav a.specialMargin {
  margin: 0.5em 2.5em;
}

a {
  text-decoration: none;
  -webkit-transition: color .5s linear;
  -moz-transition: color .5s linear;
  -ms-transition: color .5s linear;
  -o-transition: color .5s linear;
  transition: color .5s linear;
}

a:hover {

}

.btn {
  cursor: pointer;
  margin: 20px;
}

.specialResponsive {
  display: none;
}

@media (max-width: 1120px) {
  nav a {
    margin: 0.5em 1.2em;
  }

  nav a.specialMargin {
    margin: 0.5em 1.2em;
  }
}

@media (max-width: 1025px) {
  .specialResponsive {
    display:block;
  }
}



