@media screen and (max-width: 850px) {
    .clients p {
        width: calc(100% - 1em);
        text-align: center;
    }
}
@media screen and (max-width: 760px) {

    .center-items {
        justify-content: flex-start;
        align-items: flex-start;
    }

    .content {
        margin: 3em 0.5em 0 0.5em;
    }

    #clients {
        height: 100%;
    }
}
@media screen and (max-width: 479px) {
    .clients {
        height: auto;
    }

    .article-featured-img {
        width: 100%;
    }

    .article-container {
        width: 100%;
    }

}