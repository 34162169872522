#home {
    .iframe-container {
        width: 80%;
        margin: 3rem auto 1.5rem;
    }
}

.home-job-video-description {
    margin: 0 auto 1.5rem;
    width: 80%;
}

@media screen and (max-width: 760px) {
    #home {
        .iframe-container {
            width: 100%;
        }
    }
    .home-job-video-description {
        width: 100%;
    }
}