// Main layout class.
#dashboard {
  &.mdl-layout {
    position: initial;
  }
  .mdl-layout__content {
    width: calc(100% - 240px);
    display: block;
    overflow-y: scroll;
  }
  .mdl-layout__header {
    padding-right: 16px;
  }
  .mdl-layout__header-row {
    height: 70px;
    @extend .flex-horizontal;
    @extend .justify-content-space-between;
    @extend .align-items-center;
    .selectize-input {
      width: 300px;
    }
    .button {
      margin-left: 16px;
    }
  }
  .mdl-navigation {
    a:hover {
      color: unquote("rgb(#{$palette-grey-500})")
    }
    a {
      color: $input-color-text;
    }
    .log-out-link {
      margin-top: 100px;
    }
  }
  .btn {
    margin: 0;
  }
  hr {
    width: 100%;
    &.width-50-percent {
      width: 50%;
    }
  }
  .form-container {
    margin-right: 70px;
  }
  form {
    .mdl-grid {
      justify-content: flex-start;
    }
  }
  .panel {
    margin: 0;
  }
  .working-on-it {
    width: 100%;
    text-align: center;
    margin-top: $grid-desktop-margin*2;
  }
  .modal-dialog {
    margin-top: 200px;
  }
}

.modal-backdrop {
  display: none;
}

.mdl-layout--fixed-drawer > .mdl-layout__header .mdl-layout__header-row {
  padding-left: $grid-desktop-margin;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  @extend .mdl-color-text--grey-600;
}

.dashboard-header-button-container {
  display: flex;
  justify-content: flex-end;
}

.dashboard-form-container-info {
  color: #31708f;
  font-size: 75%;
  font-weight: bold;
  margin-top: 16px;
}

.edit-album-container {
  img {
    width: 100%;
  }
}
