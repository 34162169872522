.mdl-grid {
  justify-content: center;
  &.mdl-grid--no-spacing-bottom{
    .mdl-cell {
      margin-bottom: 0;
    }
  }
}

.index-container {
  max-width: 900px;
  margin: 0 auto;
}

.extra-information {
  text-align: center;
  margin-bottom: 2em;
}

.mdl-cell {
	border-radius: 2px !important;
}

/*.cell-content-width {
  width: 270px;
}*/

iframe.video {
  max-width: 820px;
  max-height: 462px;
  margin-bottom: 1em;
}

label {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.close {
  float: right;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.closeMobile {
  margin-top: -50px;
}

hr {
  width: 200px;
  @extend .marg-center;
}

.spacing {
  width: 16px;
}

.thumbnail-container {
  width: 100%;
  min-height: 128px;
  background-color: lightgrey;
  position: relative;
  &.square {
    @include aspect-ratio(1, 1);
    .content {
      margin: 0;
    }
  }
  img {
    width: 100%;
    height: auto;
    opacity: 0.4;
  }
  iframe {
    opacity: 0.4;
  }
  .button-container {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    @extend .flex;
    @extend .flex-vertical;
    @extend .flex-center;
    .title {
      text-align: center;
    }
  }
  &.is-draggable:hover {
    outline: 5px solid $info;
    cursor: pointer;
  }
}

form .thumbnail-container {
  margin-top: 5px;
  &.square {
    max-width: 200px;
    height: auto;
  }
}