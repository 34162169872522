/*
ABOUT
*/

#about.flex-horizontal {
  justify-content: center;
  align-items: center;
}

#about .paragraph {
  @extend .border;
  padding: 1em;
}

.aboutGeneral {
  margin: 5em 8.5em;
  @extend .text-justify;
}

.aboutGeneralParagraph2 {
  @extend .text-justify-last;
}

.producerInfo {
  margin: 0 auto 2em auto;
  width: 800px;
  font-size: 0.85em;
}

.producerInfo h6 {
  margin: 0;
}

.producerBio {
  flex: 1 1 500px;
}


#about img {
  height: 200px;
  min-height: 200px;
  max-height: 200px;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  margin-right: 1em;
}

@media (max-width: 1085px) {
  .aboutGeneralParagraph2 {
    -moz-text-align-last: left;
    text-align-last: left;
  }

  .text-justify-last:after {
    content: "";
    display: block;
    width: 100%;
  }
}

@media (max-width: 479px) {

  .aboutGeneral {
    margin: 2em;
  }

  .producerInfo {
    width: calc(100% - 2em);
  }

  .producerInfo h6 {
    text-align: center;
  }

  #about img {
    margin: 0 auto 1em auto;
  }

}
