/* Login page styles */

.login-overlay {
	position: absolute;
	text-align: center;
	top: 0;	
	bottom: 0;
	left: 0;
	right: 0;
	background: #CCC;
	-webkit-transition: all .4s cubic-bezier(0.175, 0.885, 0.335, 1.05);
	transition: all .4s cubic-bezier(0.175, 0.885, 0.335, 1.05);
	.logo {
		display: block;
		margin: 0 auto;
		position: relative;
		z-index: 1;
		text-transform: uppercase;
		color: white;
		font-size: 27px;
		letter-spacing: 1px;
		top: 70px;
		.smaller {
			font-size: 16px;
			font-weight: 100;
			letter-spacing: 5px;
			margin-left: -8px;
		}
	}
	.form-container {
	    position: relative;
	    z-index: 1;
	    top: 100px;
	    background: #ffffff;
	    width: 50%;
	    margin: 0 auto;
		padding: 32px;
		@extend .mdl-shadow--2dp;
	    .inputs {
			text-align: left;
			.input {
				margin-bottom: 32px;
			}
	    	.buttons-container {
	    		margin: 0 auto;
				text-align: center;
	    	}
	    }
	}	
}

.awards {
	padding-top: 46px;
	/* IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
	/* IE 5-7 */
	filter: alpha(opacity=50);
	/* Netscape */
	-moz-opacity: 0.5;
	/* Safari 1.x */
	-khtml-opacity: 0.5;
	/* Good browsers */
	opacity: 0.5;

	width: 200px;
	height: auto;

}

/* responsive */
@media screen and (max-width: 990px) {
    .form-container {
        width: 75% !important;
    }
}

@media screen and (max-width: 490px) {
    .logo {
        top: 40px !important;
    }
    .form-container {
    	top: 60px !important;
    	width: 90% !important;
    	.mdl-textfield {
    		width: 85% !important;
    	}
    }
}

@media screen and (max-width: 839px) {
    .login-footer {
        display: none;
    }
}