/*
Clients
*/

.clients {
  @extend .flex-vertical;
  @extend .flex-center;
  height: 80%;
}

.landscape-img {
  width: 720px;
  height: 413px;
}

.portrait-img {
  width: 287px;
  height: 500px;
}

.square-img {
  width: 500px;
  height: 500px;
}

/*class="text-justify text-justify-last" style="width:810px; font-weight:1200; font-style:italic;
	line-height:2.2em; font-size: 1.2em;
	margin: 0 auto;"*/

@media (max-width: 760px) {


}

@media (max-width: 479px) {
  .landscape-img {
    width: 450px;
    height: 258px;
  }

  .portrait-img {
    width: 345px;
    height: 600px;
  }

  .square-img {
    width: 450px;
    height: 450px;
  }

}