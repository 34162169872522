/* Variables here */

/* Colors */

$primary: #2979FF;
$secondary: #f5f5f5;
$warning: #ffa726;
$pink: #2979ff;
$green: #00D554;


$bg-primary: #f5f5f5;
$bg-secondary: rgba(0,0,0, 0.87);

$info: #bce8f1;

$input-color-border: #d0d0d0 !default;
$input-font-size: 13px !default;
$input-line-height: 18px !default;
$input-border: 1px solid $input-color-border !default;
$input-border-radius: 3px !default;
$input-padding: 8px !default;
$input-color-text: #303030 !default;
$input-focus-border: $info;
$label-color: $info;


/* Transition Mixin */

@mixin transition($duration) {
	transition: all $duration cubic-bezier(.27,.63,.5,1.05);
}
