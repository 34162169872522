/*
EFFECTS
*/

/*BORDERS*/
.border {
  border: 2px solid darken( $bg-primary, 5% );
}

/*HOVER EFFECTS*/

/* Common style */
figure {
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 270px;
  max-width: 270px;
  max-height: 155px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

figure img {
  position: relative;
  display: block;
  min-height: 100%;
  max-width: 100%;
  opacity: 0.6;
}

figure figcaption {
  padding: 0;
  color: #000;
  text-transform: capitalize;
  font-size: 1em;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

figure figcaption::before,
figure figcaption::after {
  pointer-events: none;
}

figure figcaption,
figure figcaption > a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
figure figcaption > a {
  z-index: 1000;
  text-indent: 200%;
  white-space: nowrap;
  font-size: 0;
  opacity: 0;
}

figure h4 {
  font-weight: 300;
}

/*figure h4 span {
  font-weight: 400;
}*/

figure h4,
figure p {
  margin: 0;
}

figure p {
  letter-spacing: 1px;
  font-size: 0.8em;
  font-weight: 600;
}

/*---------------*/
/***** Sadie *****/
/*---------------*/

figure.effect-sadie figcaption::before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: -webkit-linear-gradient(top, rgba(160,160,160,0) 0%, rgba(192,192,192,0.8) 75%);
  background: linear-gradient(to bottom, rgba(160,160,160,0) 0%, rgba(192,192,192,0.5) 75%);
  content: '';
  opacity: 0;
  -webkit-transform: translate3d(0,50%,0);
  transform: translate3d(0,50%,0);
}

figure.effect-sadie h4 {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  color: #000;
  -webkit-transition: -webkit-transform 0.35s, color 0.35s;
  transition: transform 0.35s, color 0.35s;
  -webkit-transform: translate3d(0,-50%,0);
  transform: translate3d(0,-50%,0);
}

figure.effect-sadie figcaption::before,
figure.effect-sadie p {
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
}

figure.effect-sadie p {
  position: absolute;
  bottom: 0;
  left: 0;
  padding-bottom: 2em;
  width: 100%;
  opacity: 0;
  -webkit-transform: translate3d(0,10px,0);
  transform: translate3d(0,10px,0);
}

figure.effect-sadie:hover h4 {
  color: #000;
  -webkit-transform: translate3d(0,-50%,0) translate3d(0,-40px,0);
  transform: translate3d(0,-50%,0) translate3d(0,-50px,0);
}

figure.effect-sadie:hover figcaption::before ,
figure.effect-sadie:hover p {
  opacity: 1;
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}