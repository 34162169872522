/*
TYPOGRAPHY
*/

@import url('https://fonts.googleapis.com/css?family=Lato:400,400italic,300italic,300,100italic,100,700,700italic,900,900italic');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900');
@import url('https://fonts.googleapis.com/css?family=Old+Standard+TT');
@import url('https://fonts.googleapis.com/css?family=Muli:400,300');
@import url('https://fonts.googleapis.com/css?family=Arapey');
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,300,700');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

/*FONTS*/
/*All fonts to try*/

/*'Old Standard TT', serif;
'Arapey', serif;
'Merriweather', serif;
'Muli', sans-serif;
'fanwoodregular', sans-serif;
'Raleway', sans-serif;
'Lato', sans-serif;*/

$secondary-font: 'Lato', sans-serif;
$primary-font: 'Lato', sans-serif;

body {
	font-family: $primary-font;
    font-weight: 300;
    font-size: 1.2em;
}

h1, h2, h3, h4, h6, nav a {
  font-family: $secondary-font;
  text-transform: uppercase;
}

h1 {
  margin: 20px auto;
  font-size: 5em;
  font-weight: 300;
  width: 110px;
  height: 110px;
  text-align: center;
  background-color: darken( $bg-primary, 10% );
}

h3 {
  background-color: $bg-primary;
  text-align: center;
  @extend .weight-300;
}

h4 {
  /*@extend .italic-300;*/
  font-weight: 400;
}

/*h4 span {
  @extend .weight-italic-400;
}*/

h5 {
  font-family: $primary-font;
  text-transform: uppercase;
  font-weight: 400;
}

/*MARGINS*/
h3, h4, h5, h6 {
  margin: 0;
}

h3 {
  padding: 1em 0;
}

h6 {
  margin: 1em 0 0.2em 0;
}

a:hover, a:focus {
  text-decoration: none;
}

/*FONT SIZES*/
nav a {
  font-size: 0.7em;
}

a, p {
  font-size: 1em;
  @extend .weight-300;
}

.text-small {
  font-size: 0.95rem;
}

span {
  font-size: 1em;
  font-weight: inherit;
}

.mdi {
  font-size: 1.5em;
}

#aboutUs {
  font-size: 1.2em;
}

.clients p {
  width: 850px;
  font-size: 1.25rem;
  line-height: 2.2em;
  @extend .marg-center;
  @extend .text-justify;
  @extend .text-justify-last;
  @extend .weight-300;
}

footer p {
  font-size: 1.2em;
}

/*LINE-HEIGHT*/
p {
  line-height: 1.4em;
}

#aboutUs {
  line-height: 1.2em;
}

/*SPECIAL*/
.specialCharacter {
  font-size: 0.8em;
}


/*EFFECTS*/
.txt_btm-line-eff .effect::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(0,0,0,0.1);
  content: '';
  opacity: 0;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  transform: translateY(10px);
}

.active.effect::after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  background: rgba(0,0,0,0.1);
  content: '';
  opacity: 1;
  -webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
  -moz-transition: opacity 0.3s, -moz-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

.txt_btm-line-eff .effect:hover::after,
.txt_btm-line-eff .effect:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}


