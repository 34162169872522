/*
BRAND
*/

.logo {
  margin: 2em auto;
  width: 65%;
}

@media screen and (max-width: 479px) {
  .logo {
    width: 85%;
  }
}

.logo img {
  max-width: 100%;
  height: auto;
}

.nav-logo {
  margin: -0.5em 0em 0em 0em;
  width: 300px;
  height: auto;
  opacity: 1;
  -webkit-transition: opacity .25s ease-in-out;
  -moz-transition: opacity .25s ease-in-out;
  -ms-transition: opacity .25s ease-in-out;
  -o-transition: opacity .25s ease-in-out;
  transition: opacity .25s ease-in-out;
}

.nav-logo:hover {
  opacity: 0.4;
}

.mdl-layout__drawer .nav-logo {
  margin: 2em 0 0 0;
  width: 200px;
}