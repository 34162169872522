

.input:focus {
  outline: none;
  box-shadow: 0 0 0 2pt $input-focus-border;
  border: none;
}

.input {
  margin-top: 5px;
  width: 100%;
  border-radius: $input-border-radius;
  border: $input-border;
  padding: $input-padding;
  font-family: inherit;
  font-size: $input-font-size;
  line-height: $input-line-height;
  color: $input-color-text;
}


.label {
  color: #000;
  padding-left: 0;
}