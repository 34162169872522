.articles-container {
  max-width: 600px;
  margin: 0 auto;
}

#dashboard {
  .articles-container {
    max-width: 100%;
  }
}

.categories-container {
  margin-left: 1em;
  padding: $grid-desktop-margin;
  h6 {
    margin-top: 0;
  }
}

.article-container {
  max-width: 600px;
  margin: 0 auto;
}

.article-item {
  padding-bottom: 1em;
  border-bottom: 2px solid darken( $bg-primary, 5% );
  padding: $grid-desktop-margin;
  &:hover {
    cursor: pointer;
    background-color: darken($bg-primary, 2%);
  }
}

.article-title {
  margin-top:0;
}

.article-date, .article-details {
  font-size: 13px;
}

.article-featured-img {
  width: 100%;
}

.article-paragraph {
  font-size: 14px;
  margin: 1em 1em 1em 0;
  iframe {
    width: 100%;
  }
  img {
    width: 100%;
  }
}

.article-actions {
  @extend .flex;
  @extend .justify-content-flex-end;
  .button {
    margin-left: $grid-desktop-margin;
  }
}

#article-form {
  a {
    cursor: pointer;
    text-decoration: underline;
  }
}

#dashboard {
  .article-item:hover {
    background-color: inherit;
    cursor: default;
  }
}