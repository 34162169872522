/*
DIRECTORS
*/
.video-player-container {
  @extend .flex-horizontal;
  justify-content: center;
}

.video-container {
  flex: 0 1 820px;
  .iframe-container {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.containerControls {
  flex: 1;
}

.containerImg {
  flex: 0 1 820px;
}

.slideShowImg {
  margin: 0 auto 1em auto;
}

.containerNextPrevious {
  @extend .flex-horizontal;
  @extend .flex-center;
  height: 422px;
  @extend .mdl-typography--text-uppercase;
}

.videoTitleContainerSpace {
  flex: 1 1 50px;
  align-self: center;
}

.bioDirector {
  @extend .marg-center;
  @extend .flex-horizontal;
  width: 820px;
}

.bioDirector .p_border {
  @extend .border;
  padding: 1em;
  min-height: -webkit-calc(200px - 2em - 4px);
  min-height: -moz-calc(200px - 2em - 4px);
  min-height: calc(200px - 2em - 4px);
  flex: 1;
}

.bioDirector img {
  max-width: 200px;
  min-width: 200px;
  min-height: 200px;
  max-height: 200px;
  margin-right: 1em;
}

.moreFilmsContainer.mdl-grid {
  width: 870px;
}

.moreFilmsContainer img {
  width: 403px;
  height: 226px;
}

.moreFilmsContainer figure {
  min-width: 403px;
  max-width: 403px;
  max-height: 226px;
}

@media (max-width: 1025px) {
  .containerControls {
    flex: 1 1 0;
    height: 0;
  }

  .containerControls .close {
    display: none;
  }

  .containerControls .btnNext {
    display: none;
  }

  .moreFilmsContainer.mdl-grid {
    width: 100%;
  }

  .moreFilmsContainer figure {
    min-width: 315px;
    max-width: 315px;
    max-height: 170px;
  }

  .bioDirector {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2em);
  }

  .bioDirector img {
    margin: 0 auto 1em auto;
  }

  .bioDirector p {
    flex: 1 1 360px;
  }

}


